<template>
  <v-tour
    :name="name"
    :steps="steps"
    :options="options"
  >
    <template
      slot-scope="tour"
    >
      <transition name="fade">
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          v-chakra="{
            '.v-step__content': {
              fontSize: ['12px', '14px'],
            },
          }"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :finish="tour.finish"
          style="background-color: #C7F9E3; color: #000; border-radius: 10px"
        >
          <template #actions>
            <c-flex
              gap="1rem"
              justify-content="flex-end"
              :font-size="['12px', '14px']"
            >
              <button
                @click="$emit('on-skip')"
              >
                Skip
              </button>
              <button
                style="color: #0C9500"
                @click="$emit('on-next', step)"
              >
                {{ tour.steps[tour.currentStep].nextText }}
              </button>
            </c-flex>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'v-tour',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['on-next', 'on-skip'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}

::v-deep .v-step {
  max-width: 270px;
}

::v-deep .v-step__content {
  text-align: start;
}

@media (min-width: 768px) {
  ::v-deep .v-step {
    max-width: 320px;
  }
}
</style>