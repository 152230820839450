<template>
  <c-box
    position="relative"
    width="100%"
  >
    <transition
      name="route"
      mode="out-in"
    >
      <router-view
        :is-have-meal-plan-months="isHaveMealPlanMonth"
        :program-meal-plan-months="programMealPlanMonths"
        :is-loading-program="isLoadingProgramMonths"
        :is-loading-data="isLoadingData"
        :data="data"
        :active-program="activeProgram"
        :is-tour-active="isTour"
      />
    </transition>
    <TourMealPlan
      :name="constants.mealPlanTour['tourName']"
      :steps="tourStepsFiltered"
      :options="tourOptions"
      @on-next="(step) => onNextStepTour(step)"
      @on-skip="onSkipTour"
    />
  </c-box>
</template>

<script>
import {
  reqMealPlansActiveProgramMonths,
  reqClient_mealPlans_activeProgram,
  reqClient_mealPlans_preview,
  reqClient_mealPlans_tour_status_get,
  reqClient_mealPlans_tour_status_put,
  reqClient_mealPlans_read_put,
} from '@/requests/dietela-api/client/meal-plan'
import TourMealPlan from '@/components/meal-plan/_widgets/tour-meal-plan.vue'
import { mealPlanTour } from '@/constants/meal-plans'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import EventBus from '@/utils/event-bus'
import { mapState } from 'vuex'

export default {
  components: {
    TourMealPlan,
  },
  mixins: [mixinsCheckBreakpoints()],
  data() {
    return {
      isHaveMealPlanMonth: false,
      programMealPlanMonths: [],
      isLoadingProgramMonths: false,
      isLoadingData: false,
      data: {},
      activeProgram: {},
      isTour: false,
      tourOptions: {
        debug: true,
        useKeyboardNavigation: false,
        startTimeout: 500,
      },
      tourSteps: [],
      tourStepsFiltered: [],
      isFetchProgramMonths: false,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    constants() {
      return {
        mealPlanTour,
      }
    },
    triggerTour() {
      return {
        activeProgram: this.activeProgram,
        route: this.$route,
      }
    },
  },
  watch: {
    triggerTour: {
      immediate: true,
      async handler(val) {
        if (!this.isFetchProgramMonths) {
          await this.getMealPlanProgramMonths()
        }

        if (this.programMealPlanMonths.length > 0 && val.route?.params?.month) {
          await this.getMealPlanPreview(val.route?.params?.month)
        }

        if (val?.activeProgram?.id) {
          this.getMealPlanTour()
        }
      },
    },
  },
  methods: {
    async getMealPlanProgramMonths() {
      try {
        this.isFetchProgramMonths = true
        this.isLoadingProgramMonths = true
        this.programMealPlanMonths = []
        const res = await reqMealPlansActiveProgramMonths(this.$store.getters.axios, {
          programId: this.$route.query?.programId || '',
        })
        this.programMealPlanMonths = res?.data?.data
        this.isHaveMealPlanMonth = true

        await this.getActiveProgram()
      } catch(err) {
        if (err?.response?.data?.message === 'Program Not Found') {
          this.isHaveMealPlanMonth = false
          this.$router.push({
            name: 'client.meal-plan.meal-plan-schedule',
            params: {
              month: 1,
            },
            query: {
              ...this.$route.query,
            },
          }).catch(() => {})
        }
      } finally {
        this.isLoadingProgramMonths = false
      }
    },
    async getActiveProgram() {
      if (this.$route.query?.programId) {
        this.activeProgram = {
          id: this.$route.query.programId,
          clientId: this.user?.id,
        }
      } else {
        const res2 = await reqClient_mealPlans_activeProgram(this.$store.getters.axios)
        this.activeProgram = res2?.data?.data
      }
    },
    async getMealPlanPreview(month) {
      this.isLoadingData = true
      const res = await reqClient_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.activeProgram?.clientId,
          programId: this.activeProgram?.id,
          month: month,
        },
      )
      this.data = res?.data?.data
      this.isLoadingData = false
    },
    async getMealPlanTour() {
      this.isTour = false
      // this.tourSteps = []
      // this.tourStepsFiltered = []
      const res = await reqClient_mealPlans_tour_status_get(this.$store.getters.axios, {
        programId: this.activeProgram?.id,
        onboardingName: this.constants.mealPlanTour['onboardingName'],
      })

      this.tourSteps = res?.data?.data

      const filterByPage = this.tourSteps?.filter((it) => it?.onboardingTour?.page === this.$route.name && it?.isRead === 0)
      if (filterByPage.length > 0) {
        this.tourStepsFiltered = filterByPage.map((step) => {
          const stepNumber = parseInt(step?.onboardingTour?.steps)

          const target = (!['sm', 'md'].includes(this.currentBreakpoint) && stepNumber === 1 && step?.onboardingTour?.page !== 'client.meal-plan' ? `.${step?.onboardingTour?.page.replaceAll('.', '_')}_${step?.onboardingTour?.steps}-desk` : `.${step?.onboardingTour?.page.replaceAll('.', '_')}_${step?.onboardingTour?.steps}`)

          const pageUrl = step?.onboardingTour?.pageUrl && this.$route.query?.programId ? `${step?.onboardingTour?.pageUrl}&programId=${this.$route.query?.programId}` : step?.onboardingTour?.pageUrl
          
          return {
            onboardingTourUserId: step?.id,
            target: target,
            content: step?.onboardingTour?.description,
            nextText: step?.onboardingTour?.buttonText,
            page: step?.onboardingTour?.page,
            pageUrl: pageUrl,
            step: stepNumber,
            params: {
              highlight: true,
              enableScrolling: false,
            },
          }
        })
        setTimeout(() => {
          if (!this.$route.params.month || Object.keys(this.data).length > 0) {
            this.isTour = true
            this.$tours[this.constants.mealPlanTour['tourName']].start()
          }
        }, 500)
      }
    },
    onSkipTour() {
      this.onReadStepTour(this.tourStepsFiltered[0]?.onboardingTourUserId, 1)

      this.isTour = false
      this.$tours[this.constants.mealPlanTour['tourName']].skip()
    },
    async onNextStepTour(currentStep) {
      const nextStep = this.tourStepsFiltered.find((it) => it?.step === (currentStep?.step + 1))
      
      this.onReadStepTour(currentStep?.onboardingTourUserId, 0)

      if (currentStep?.pageUrl) {
        this.isTour = false
        this.$tours[this.constants.mealPlanTour['tourName']].finish()

        if (currentStep?.page === 'client.meal-plan' && currentStep?.step == 1) {
          try {
            await reqClient_mealPlans_read_put(this.$store.getters.axios, {
              clientId: this.activeProgram?.clientId,
              programId: this.activeProgram?.id,
              month: 1,
            })
          } catch(err) {
            console.log(err)
          }
        }

        setTimeout(() => {
          this.$router.push(currentStep.pageUrl)
        }, 100)
      } else if (nextStep) {
        if (nextStep?.target === '.client_meal-plan_menu-recommendation_5') {
          EventBus.$emit('OPEN_MODAL_MENU_RECOMMENDATION')
          setTimeout(() => {
            this.$tours[this.constants.mealPlanTour['tourName']].nextStep()
          }, 100)
        } else {
          this.$tours[this.constants.mealPlanTour['tourName']].nextStep()
        }
      } else {
        this.isTour = false
        this.$tours[this.constants.mealPlanTour['tourName']].finish()
      }
    },
    onReadStepTour(onboardingTourUserId, skip) {
      const res = reqClient_mealPlans_tour_status_put(this.$store.getters.axios, {
        onboardingTourUserId,
        skip,
      })
      return res
    },
  },
}
</script>

<style scoped>
::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}
</style>