var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tour', {
    attrs: {
      "name": _vm.name,
      "steps": _vm.steps,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(tour) {
        return [_c('transition', {
          attrs: {
            "name": "fade"
          }
        }, _vm._l(tour.steps, function (step, index) {
          return tour.currentStep === index ? _c('v-step', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                '.v-step__content': {
                  fontSize: ['12px', '14px']
                }
              },
              expression: "{\n          '.v-step__content': {\n            fontSize: ['12px', '14px'],\n          },\n        }"
            }],
            key: index,
            staticStyle: {
              "background-color": "#C7F9E3",
              "color": "#000",
              "border-radius": "10px"
            },
            attrs: {
              "step": step,
              "previous-step": tour.previousStep,
              "next-step": tour.nextStep,
              "stop": tour.stop,
              "skip": tour.skip,
              "is-first": tour.isFirst,
              "is-last": tour.isLast,
              "labels": tour.labels,
              "finish": tour.finish
            },
            scopedSlots: _vm._u([{
              key: "actions",
              fn: function fn() {
                return [_c('c-flex', {
                  attrs: {
                    "gap": "1rem",
                    "justify-content": "flex-end",
                    "font-size": ['12px', '14px']
                  }
                }, [_c('button', {
                  on: {
                    "click": function click($event) {
                      return _vm.$emit('on-skip');
                    }
                  }
                }, [_vm._v(" Skip ")]), _c('button', {
                  staticStyle: {
                    "color": "#0C9500"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.$emit('on-next', step);
                    }
                  }
                }, [_vm._v(" " + _vm._s(tour.steps[tour.currentStep].nextText) + " ")])])];
              },
              proxy: true
            }], null, true)
          }) : _vm._e();
        }), 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }