var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%"
    }
  }, [_c('transition', {
    attrs: {
      "name": "route",
      "mode": "out-in"
    }
  }, [_c('router-view', {
    attrs: {
      "is-have-meal-plan-months": _vm.isHaveMealPlanMonth,
      "program-meal-plan-months": _vm.programMealPlanMonths,
      "is-loading-program": _vm.isLoadingProgramMonths,
      "is-loading-data": _vm.isLoadingData,
      "data": _vm.data,
      "active-program": _vm.activeProgram,
      "is-tour-active": _vm.isTour
    }
  })], 1), _c('TourMealPlan', {
    attrs: {
      "name": _vm.constants.mealPlanTour['tourName'],
      "steps": _vm.tourStepsFiltered,
      "options": _vm.tourOptions
    },
    on: {
      "on-next": function onNext(step) {
        return _vm.onNextStepTour(step);
      },
      "on-skip": _vm.onSkipTour
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }